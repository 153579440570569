// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyApmn1gnJFskDH5OJUrQi5v6tIoPpcKacA",
  authDomain: "indbnew1.firebaseapp.com",
  databaseURL: "https://indbnew1-default-rtdb.firebaseio.com",
  projectId: "indbnew1",
  storageBucket: "indbnew1.appspot.com",
  messagingSenderId: "283258058944",
  appId: "1:283258058944:web:39770b4e54cdd2564cd525",
  measurementId: "G-Q1YXWNYBGV"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };